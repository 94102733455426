.ant-card-body{
    width: 100% !important;
    padding: 0px !important;
    font-family: 'Noto Sans JP', sans-serif;
}
.ant-card-bordered{
    width: 100% !important;
    border-radius: 0;
    background-color: #F7F9F9;
}
.card-details p{
    margin-bottom: 10px;
    padding-left: 15px;
    min-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.property-info p .CardDetailsh, .owner-info p .CardDetailsh{
    width: 95px;
    display: inline-block;
}

.property-info table tbody td {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    padding:0;
    background-color: transparent;
}

.property-info table .title td {
    color:#051D34;
    font-weight: 600;
}

.property-info table .value td:nth-child(1) {
    color:#979A9A;
    padding-left: 10px;
}

.property-info table .value td:nth-child(2) {
    color:#17202A;
}

.edit-icon {
    position: absolute;
    right: 0px;
}

.edit-icon img {
    height: 24px;
}

.carousel-control-prev-icon {
    background-image: url(/public/assets/images/left-icon.png) !important;
}
.carousel-control-next-icon {
    background-image: url(/public/assets/images/right-icon.png) !important;
}
.carousel-control-next{
    right: 0 !important;
    opacity: 10;
}
.carousel-control-prev{
    left: 0 !important;
    opacity: 10;
}
.carousel-indicators [data-bs-target]{
    width: 10px;
    height: 10px;
    background-color: #fff;
    opacity: 1;
    border: none !important;
    border-radius: 30px;
}
.carousel-indicators .active{
    width: 35px;
    background-color: #1D262F;
}
.carousel-item .yellow-label{
    top: 25px;
}

.carousel {
    position: relative;
    min-height: 100%;
    /* height: 100%; */
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    min-height: 100%;
    /* height: 100%; */
}
.carousel-item {
    /* height: 100%; */
}

.carousel-item img{
    background-color:#fff;
    width:100%;
    height: 220px;
    object-fit: contain;  
    /* object-fit: cover;   */
    /* height:100%; */
}

.clicked-card{
    background-color: white;
    border-left: 5px solid #F15C00 !important;
}
.yellow-label2{ 
    /* background-color: #FFDE5C; */
    padding: 15px 15px;
    border-radius: 35px 0px 0px 35px;
    position: absolute;
    right: 0px;
    top: 15px;
    color: #051D34;
    height: 20px;
    display: flex;
    align-items: center;
    z-index: 100;
    font-size: 14px;
}
.tag-box .bg-secondary{
background-color:#EDEBE8 !important;
border-radius: 10px;
color: #2D2925 !important;
}
.text-brack{
    display: inline-block;
    width: 500px;
    word-wrap: break-word;
}

@media only screen and (min-device-width : 320px) and (max-device-width :768px){
    .carousel-item img{
    }
    .CardDetailsh1{
        width: 100%;
    }
    .carousel-indicators [data-bs-target]{
        width: 10px;
        height: 10px;
    }
    .clicked-card {
        border: 3px solid #F15C00 !important;
    }
    .edit-icon {
        position: absolute;
        right: 15px;
        top: -5px;
    } 
    
    .text-brack{
        width: 220px;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width :1024px){
    .text-brack{
        width: 600px;
    }
}

.select-button {
    height: 20px;
}