.accordion-button:not(.collapsed){
    color: black!important;
}

.cardsrcollbar{
    height: 100%;
    border-radius: 10px;
    border: #D0D3D4 solid 1px;
    overflow: scroll;
}

.accordion-button::after{
    display: none;
}

/* ヘッダ */
.main-header{
    background-color: #051D34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    /* position: fixed; */
    top:0px !important;
    width: 100% !important;
    z-index: 10000;
}
.main-header h3{
    font-size: 18px;
    margin-bottom: 0px;
}
.main-header .btnLoggout{
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
}

.main-header .btnLoggout img {
    height: 18px;
}

.main-header p{
    font-size: 20px;
    margin-bottom: 0px;
}

/* メインコンテンツ(検索条件・地図・物件一覧) */
.main-contents {
    height: calc(100% - 70px);
}

/* メインコンテンツ(地図・物件一覧) */
.main-contents-container {
    height:calc(100% - 50px);
}

/* 検索条件 */
.search-box {
    min-height:50px;
}
.search-box span{
    font-size: 14px;
}

.form-check-input:checked {
    background-color: #051D34;
    border-color: #051D34;
}
.accordion-button{
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    justify-content: flex-end;
    color: #000;
}
.accordion-button.collapsed svg{
    margin-right: 15px;
    color: #99a199;
    transform: rotate(180deg);
}
.accordion-button svg{
    margin-right: 15px;
    color: #99a199;
    transform: rotate(0deg);
}
.accordion-button::after{
    position: absolute;
    right: 175px;
}
.accordion-item{
    border: none;
    box-shadow: 0px 39px 48px 0px #B0B0B00D;
}
.bottom-btn{
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
}
.bottom-btn .btn-primary{
    background-color: #1D262F !important;
    box-shadow: none !important;
    outline: none !important;
    border-color: transparent !important;
}
.down-icon{
    transform: rotate(180deg);
}

@media only screen and (min-device-width : 320px) and (max-device-width :768px) {
    .main-header{
        height: auto;
    }
    .main-header h3{
        font-size: 16px;
    }
    .main-header p{
        font-size: 16px;
        margin-bottom: 0px;
        width: 134px;
        display: flex;
        align-items: center;
    }
    .bottom-btn .btn-primary{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .bottom-btn .btn-primary img{
        width: 25px;
    }
    .house-map-box{
        width: auto;
    }
    .yellow-label{
        font-size: 12px;
    }
    .house-map-details p{
        font-size: 10px;
    }
    .yellow-label {
        background-color: #FFDE5C;
        padding: 5px 15px;
        border-radius: 35px 0px 0px 35px;
        position: absolute;
        right: 0;
        bottom: 15px;
        font-weight: 700;
        color: #051D34;
        height: 35px;
        display: flex;
        align-items: center;
    }
    .polygon-icon {
        position: absolute;
        bottom: -34px;
        left: 25px;
        width: 60px;
    }
    .main-header .btnLoggout{
        font-size: 18px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width :992px){
    .bottom-btn .btn-primary{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .bottom-btn .btn-primary img{
        width: 30px;
    }
}
@media only screen and (min-device-width : 992px) and (max-device-width :1399px){
    .bottom-btn .btn-primary{
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    .bottom-btn .btn-primary img{
        width: 35px;
      
    }
}


.add-btn{
    background-color: #051D34 !important;
    font-size: 15px;
    border-radius: 35px;
    outline: none;
    border-color: transparent !important;
    padding: 8px 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: absolute;
    right: 75px;
    bottom: 40px;
}
.house-map-box{
    background-color: #F15C00;
    padding: 10px;
    position: absolute;
    border-radius: 15px;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 500px;
}
.polygon-icon{
    position: absolute;
    bottom: -64px;
    left: 25px;
    width: 115px;
}
.house-map-details{
    color: #fff;
    min-height: 100%;
}
.house-map-details p{
    font-size: 22px;
}
.yellow-label{
    background-color: #FFDE5C;
    padding: 5px 15px;
    border-radius: 35px 0px 0px 35px;
    position: absolute;
    right: 0px;
    bottom: 10px;
    font-weight: 700;
    color: #051D34;
    height: 35px;
    display: flex;
    align-items: center;
}
@media only screen and (min-device-width : 320px) and (max-device-width :768px) {
    .house-map-box{
        width: 250px;
    }
    .main-header, .container-fluid {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .yellow-label{
        font-size: 8px;
    }
    .add-btn{
        font-size: 14px;
        padding: 4px 13px !important;
        bottom: 25px;
        right: 25px;
    }
    .house-map-details p{
        font-size: 10px;
    }
    .yellow-label {
        background-color: #FFDE5C;
        padding: 5px 15px;
        border-radius: 35px 0px 0px 35px;
        position: absolute;
        right: 0px;
        bottom: 10px;
        font-weight: 700;
        color: #051D34;
        height: 20px;
        display: flex;
        align-items: center;
        
    }
    .yellow-label2{ 
        background-color: #FFDE5C;
        padding: 5px 15px;
        border-radius: 35px 0px 0px 35px;
        position: absolute;
        right: -10px;
        top: -4px;
        font-weight: 700;
        color: #051D34;
        height: 20px;
        display: flex;
        align-items: center;
        z-index: 10000;
    }
    .polygon-icon {
        position: absolute;
        bottom: -34px;
        left: 25px;
        width: 60px;
    }
}


.gm-style-iw-d p{
    font-weight: 800;
    padding: 0px;
    margin: 0px !important;
    }

.gm-style img {
    max-width: none;
    padding: 8px 7px;
}

.highlight img {
    max-width: none;
    padding: 0px !important;
    border-radius: 15px !important;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}


:where(.css-dev-only-do-not-override-2i2tap).ant-select-multiple .ant-select-selector{
    height: 100% !important;
}
