body{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
}
.login-details h1{
    font-weight: 900;
}
.login-details {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-form-box{
    background-color: #E9ECEF;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.log-form .form-label{
    font-weight: 500;
}
.log-form-box{
    background-color: #fff;
    box-shadow: 40px 49px 64px 60px #9AA1A617;
    border-radius: 20px;
    width: 495px;
    height: 525px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.log-form .form-control{
    background-color: #fff !important;
    box-shadow: none;
    outline: none;
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 40px 49px 64px 60px #9AA1A617;
}
  .log-form .form-control::-ms-input-placeholder { /* Edge 12-18 */
    color: #000;
  }
  
  .log-form .form-control::placeholder {
    color: #000;
  }
.login-icon{
    position: absolute;
    top: 45px;
    left: 20px;
    cursor: pointer;
    font-size: 20px !important;
}
.login-form .btn-primary{
    background-color: #051D34 !important;
    font-size: 17px;
    border-radius: 4px;
    outline: none;
    border-color: transparent !important;
    padding: 13px;
    font-weight: 700;
}
.login-form .btn-disabled{
    background-color: #E9ECEF !important;
    font-size: 17px;
    border-radius: 4px;
    outline: none;
    border-color: transparent !important;
    padding: 13px;
    font-weight: 700;
}
.login-form p{
    color: #999FAE;
}
.login-form h2{
    font-weight: 700;
}

.boxshowd{
    border-color: transparent !important;
    box-shadow: 0px 0px 15px 0px #eeeeee !important;
}
@media only screen and (min-device-width : 320px) and (max-device-width :768px){
    .login-details, .login-form-box, .login-form{
        padding: 15px !important;
    }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 1280px){
    .log-form-box{
        width: auto;
        height: auto;
    }
}