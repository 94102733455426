.pagination-box{
    padding: 15px;
}
.pagination-box .pagination{
    margin-bottom: 0px;
    margin-right: 15px;
}
.page-link.active, .active > .page-link {
    z-index: 3;
    color: #ffff !important;
    background-color: #051D34 !important;
    border-color: #051D34 !important;
}
.page-link{
    color: #000;
}
.page-link:hover{
    color: #ffff !important;
    background-color: #051D34 !important;
    border-color: #051D34 !important;
}