.modal-header{
    padding: 0px;
}

.modal-header{
    background-color: #051D34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fontWidth {
font-weight: 600;
}

.ModalBox {
 z-index: 20000;
}
.buttn.btn:hover {
    color: red !important;
}

.propertyInfo.form-control:focus {
    color: var(--bs-body-color);
    outline: 0;
}

.propertyInfo.form-select:focus {
    outline: 0;
}

.cancel-btn{
    border-color: #051D34 !important;
    color: #051D34;
    font-size: 17px;
}

.modal-footer .btn:hover {
    color: #fff!important;
    background-color:  #051D34;
}

.modal-footer {
    display: flex !important;
    justify-content: space-between !important;
    flex-shrink: 0 !important;
    border: none !important; 
    background-color:none !important 
}

.Proceed-btn {
    background-color: #051D34 !important;
    font-size: 17px;
    outline: none;
    border-color: transparent !important;
    display: flex;
    align-items: center;
   
}


.uploadButtonsContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .upload-btn-wrapper .btn {
    background-color: white;
    padding: 8px 20px;
    font-size: 20px;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .sliderUploadedImageContainer{
    height: 150px;
    position: relative;
    margin-top: 5px;
}

.sliderUploadedImageContainer img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    padding-right: 5px;
}


.imageUploadContainer2 .slick-prev:before {
    font-family: 'slick';
    line-height: 1;
    opacity: .75;
    color: var(--white)!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px !important; 
    margin-left: 30px !important;
    opacity: 1 !important;
     
    
}
.slick-prev{
    z-index: 100 !important;
}

.slick-next{
    z-index: 100;
}
.sliderUploadedImageContainer{
    width: 175px !important;
}
.sliderUploadedImageContainer img{
    width: 175px;
    margin-right: 10px;
    border-radius: 10px !important;
}
.parentMultiImage .slick-initialized .slick-slide{
    width: auto !important;
    text-align: left !important;
    margin-right: 18px !important;   
}

.pac-container {
    z-index: 99999999999 !important;
    /* position: relative !important; */
  }
.ant-select-dropdown{
    z-index: 1000000000 !important;
}
.ant-select-selection-overflow-item{
    background-color: #051D34 !important;
    font-size: 17px !important;
    outline: none;
    border-color: transparent !important;
    color: #fff;
    border-radius: 8px !important;
    overflow: visible !important;
    margin-right: 5px !important;
}
:where(.css-dev-only-do-not-override-2i2tap).ant-select-multiple .ant-select-selection-search{
    margin-inline-start:0px !important;
}

.ant-select-selection-item-remove{
    position: absolute ;
    top: -5px;
    background: #051D34;
    right: -10px;
    border-radius: 15px;
    padding: 3px;
    border: 1px solid #fff;
}
.ant-select-selection-item{
    overflow: visible !important;
}
.ant-select-selector{
    height: 40px !important;
}
.ant-select-selection-item-remove svg{
    color: #fff;
    fill: #fff;
}
.parentMultiImage{
    width: auto;
    overflow: hidden;
}

.buttonCross{
    position: absolute;
    top: -5px;
    right: 2px;
    border-radius: 100%;
    border: none;
    background-color:#051D34;
    color: #fff;
    height: 25px;
    width: 25px;
    font-size: 15px;
    padding: 0px;
    display: flex;
    justify-content: center;
 }
.upload-btn-wrapper .btn img{
    width: 60px;
    cursor: pointer;
}
.imageUploadContainer2 .slick-arrow{
    display: none !important;
}
.address .form-control{
    width: 75%;
}
.year .form-control{
    width: 400px;
}
.sistuation-input .form-select, .property-input .form-select{
    width: 400px;
}
.remarks-input .form-control{
    width: 75%;
}
.delete-modal p{
    font-size: 24px;
    font-weight: 500;
}
.modal-footer .btn-danger{
    background-color: #C00000 !important;
    border-color: transparent !important;
    font-size: 17px;
}
.delete-footer{
    justify-content: center !important;
}
.autoWidth{
    width: 330px;
}

.autoWidth .editAuto {
    background-color: #051D34;
    border: #051D34;
}
.association .crossIcon{
    background-color: #051D34;
    color: #fff;
}
.slick-track {
    margin-left: 0px !important;
}
.popup-body, .modal-footer{
    padding-left: 35px;
    padding-right: 35px;
}
.ant-select, .search-location-input{
    width: 75% !important;
    margin-left: 0px;
}
 @media only screen and (min-device-width : 320px) and (max-device-width :768px){
    .btn-outline-danger, .cancel-btn, .Proceed-btn, .modal-footer .btn-danger{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .address .form-control{
        width: 100%;
    }
    .year .form-control{
        width: 100%;
    }
    .sistuation-input .form-select, .property-input .form-select{
        width: 100%;
    }
    .remarks-input .form-control{
        width: 100%;
    }
    .add-popup-body{
        padding-left: 15px;
        padding-right: 15px;
    }
    .ant-select, .search-location-input{
        width: 100% !important;
    }
}


@media only screen and (min-device-width : 320px) {
    .MultiImageSlider{
        width: auto;
    }
}

@media only screen and (min-device-width : 768px) {
    .MultiImageSlider{
        width: auto;
    }
}

@media only screen and (min-device-width : 1024px) {
    .MultiImageSlider{
        width: auto;
    }
}

@media only screen and (min-device-width : 1440px) {
    .MultiImageSlider{
        width:auto;
    }
}


@media only screen and (min-device-width : 2560px) {
    .MultiImageSlider{
        width: auto;
    }
}


@media only screen and (min-device-width : 320px) and (max-device-width :768px){
    .login-details, .login-form-box, .login-form{
        padding: 15px !important;
    }
    .upload-btn-wrapper .btn{
        padding: 0px;
    }
    .upload-btn-wrapper .btn img {
        width: 35px;
        cursor: pointer;
    }
    .upload-btn-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        overflow: hidden;
        display: inline-block;
    }
    .parentMultiImage .slick-list {
        display: inline-flex !important;
    }
}
:where(.css-dev-only-do-not-override-2i2tap).ant-select-multiple .ant-select-selector{
    height: 100% !important;
}
.ant-select-selection-overflow-item{
    margin: 3px;
}
.ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix{
    background-color: transparent !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-select-multiple .ant-select-selector{
    height: 100% !important;
}

.Toastify__toast-container{
    z-index: 100000000000 !important;
}
.parentMultiImage .slick-list{
    display: contents;
}
.calssName2 .slick-list{
    display: inline-flex;
    width: 970px;
}
.img-slider-box{
    position: relative;
}