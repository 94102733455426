.gm-style-iw .gm-style-iw-c{
 max-width: 250px !important;
}

.image-width{
  width: 60px;

  background-color: #fff;
  padding: 5px;
}

.gm-ui-hover-effect{
  display: none !important;
}

:root {
  --building-color: #FF9800;
  --house-color: #0288D1;
  --shop-color: #7B1FA2;
  --warehouse-color: #558B2F;
}

/*
 * Optional: Makes the sample page fill the window.
 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/*
 * Always set the map height explicitly to define the size of the div element
 * that contains the map.
 */
#map {
  height: 100%;
  width: 100%;
}

/*
 * Property styles in unhighlighted state.
 */
.property {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #263238;
  display: none;
  font-size: 14px;
  gap: 15px;
  height: 74px;
  justify-content: center;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
  margin-bottom: 30px;
}
/* 
.property::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #FFFFFF; */
  /* content: ""; */
  /* height: 0;
  left: 50%;
  position: absolute;
  top: 97%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}

.property .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
}

.property .icon svg {
  height: 20px;
  width: auto;
} */
.highlight .icon{
  width: 115px;
  height: 115px;
}
.property .details {
  display: none;
  flex-direction: column;
  flex: 1;
  padding-bottom: 22px;
}

.property .address {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.property .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property .features > div {
  align-items: center;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/*
 * Property styles in highlighted state.
 */
.property.highlight {
  background-color: #F15C00;
    border-radius: 8px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
    height: auto;
    padding: 10px 10px;
    width: 450px;
    margin-bottom: 34px;
    align-items: flex-start;
    margin-left: 289px;
}
.property .icon img{
  border-radius: 15px;
}
.property.highlight::after {
  border-top: 9px solid #F15C00;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  height: 0;
  left: 80px;
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}

.property.highlight .details {
  display: flex;
}

.property.highlight .icon svg {
  width: 50px;
  height: 50px;
}

.property .bed {
  color: #FFA000;
}

.property .bath {
  color: #03A9F4;
}

.property .size {
  color: #388E3C;
}

/*
 * House icon colors.
 */
.property.highlight:has(.fa-house) .icon {
  color: var(--house-color);
}

.property:not(.highlight):has(.fa-house) {
  background-color: var(--house-color);
}

.property:not(.highlight):has(.fa-house)::after {
  border-top: 9px solid var(--house-color);
}

/*
 * Building icon colors.
 */
.property.highlight:has(.fa-building) .icon {
  color: var(--building-color);
}

.property:not(.highlight):has(.fa-building) {
  background-color: var(--building-color);
}

.property:not(.highlight):has(.fa-building)::after {
  border-top: 9px solid var(--building-color);
}
.img-thumbnail {
  width: 74px !important;
  height: 74px !important;
  border-radius: 10px;
}
/*
 * Warehouse icon colors.
 */
.property.highlight:has(.fa-warehouse) .icon {
  color: var(--warehouse-color);
}

.property:not(.highlight):has(.fa-warehouse) {
  background-color: var(--warehouse-color);
}

.property:not(.highlight):has(.fa-warehouse)::after {
  border-top: 9px solid var(--warehouse-color);
}

/*
 * Shop icon colors.
 */
.property.highlight:has(.fa-shop) .icon {
  color: var(--shop-color);
}

.property:not(.highlight):has(.fa-shop) {
  background-color: var(--shop-color);
}

.property:not(.highlight):has(.fa-shop)::after {
  border-top: 9px solid var(--shop-color);
}

#map {
  border-radius: 10px;
  border: #D0D3D4 solid 1px;
}


@media only screen and (min-device-width : 320px) and (max-device-width :768px) {
  .property.highlight{
    width: 100% !important;
    margin-left: 0px;
  }
  .property.highlight::after{
    left:50%
  }
}